import React from 'react'
import { Link } from 'gatsby'
import { LinkAnchor } from '../components/LinkAnchor'
import { Box, Container, Icon, P, H1 } from '@allied-solutions/affinity'
import { FullScreenLayout, Nav } from '../components'
import styled from 'styled-components'

import {
  Document1Checked,
  Temple,
  Shield,
} from '@allied-solutions/affinity-react-icons/dist/16px'

const StyledBox = styled(Box)`
  box-sizing: border-box;
  height: 100%;
  padding: ${props => props.theme.space[6]} ${props => props.theme.space[10]}
    ${props => props.theme.space[11]} ${props => props.theme.space[10]};
  border-radius: ${props => props.theme.space[3]};

  &:hover,
  &:focus {
    transform: translateY(-1rem);
  }
`

const FourOhFour = () => {
  return (
    <FullScreenLayout nav={<Nav />}>
      <Container px={8}>
        <Box mt={['100px', null, 0]} mx="auto" textAlign="center">
          <P
            mb={0}
            typeStyle="titleLarge"
            textAlign="center"
            display="block"
            fontWeight="900"
          >
            Oops...
          </P>
          <H1 typeStyle="displayMedium" fontWeight="900">
            We couldn’t find that page.
          </H1>
          <P
            mb={0}
            typeStyle="titleLarge"
            textAlign="center"
            display="block"
            mt=""
          >
            Go back to the <LinkAnchor to="/">homepage</LinkAnchor>.
          </P>
          <Box
            display={[null, null, null, 'flex']}
            justifyContent="space-between"
            mt="24"
          >
            <Link
              to="/support/about-collateral-protection-insurance-cpi/"
              css={`
                width: 30%;
                text-decoration: none;
              `}
            >
              <StyledBox border="2px solid" borderColor="muted">
                <Icon
                  src={Temple}
                  color="Blue1000"
                  display="flex"
                  justifyContent="center"
                  mt="5"
                />
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  color="gray1000"
                  textAlign="center"
                  fontWeight="1000"
                  display="block"
                  mt="4"
                >
                  What is collateral protection insurance?
                </P>
              </StyledBox>
            </Link>
            <Link
              to="/support/why-did-i-receive-a-letter-telling-me-to-verify-my-insurance/"
              css={`
                width: 30%;
                text-decoration: none;
              `}
            >
              <StyledBox
                border="2px solid"
                borderColor="muted"
                mt={['3', '3', '3', '0']}
              >
                <Icon
                  src={Document1Checked}
                  color="Blue1000"
                  display="flex"
                  justifyContent="center"
                  mt="5"
                />
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  color="gray1000"
                  textAlign="center"
                  fontWeight="900"
                  display="block"
                  mt="4"
                >
                  Do I have to verify my insurance?
                </P>
              </StyledBox>
            </Link>
            <Link
              to="/support/quick-guide-to-myinsuranceinfo/"
              css={`
                width: 30%;
                text-decoration: none;
              `}
            >
              <StyledBox
                border="2px solid"
                borderColor="muted"
                mt={['3', '3', '3', '0']}
              >
                <Icon
                  src={Shield}
                  color="Blue1000"
                  display="flex"
                  justifyContent="center"
                  mt="5"
                />
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  color="gray1000"
                  textAlign="center"
                  fontWeight="900"
                  display="block"
                  mt="4"
                >
                  A Quick Guide to MyInsuranceInfo
                </P>
              </StyledBox>
            </Link>
          </Box>
        </Box>
      </Container>
    </FullScreenLayout>
  )
}

export default FourOhFour
